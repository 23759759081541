import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import { IndexedRow } from "@uderly/react-uderly-ui/models";
import { createItemRequest } from '@uderly/react-uderly-ui/api';
import IqSpaceApi from '../../api/IqSpace';
import RemoteSelect from '../../framework/RemoteSelect';
import { DataLoader, QuickModule } from "@uderly/react-uderly-ui";
import Settings from '../settings';
import { GridColDef } from "@mui/x-data-grid";
import AppContext from '../AppContext';

export interface PlaylistsSelectProps {
  exclude?: IndexedRow;
  compactMode?: boolean; // Display it as a dropdown
  playlistId?: number;
  onSelect?: (item: any) => void;
  quickCreatePlaylist?: () => void; // TODO: Move in this file
  onPlaylistChanged?: (e: any, playlistId: number) => void; // TODO: Move in this file
}

const PlaylistsSelect = (props: PlaylistsSelectProps) => {
  const { exclude, compactMode, onSelect, quickCreatePlaylist, onPlaylistChanged } = props;
  const { t } = useTranslation("translation");

  const [playlists, setPlaylists] = useState<[] | null>(null);
  const [playlistId, setPlaylistId] = useState<number | undefined>(props.playlistId);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showInsertPlaylist, setShowInsertPlaylist] = useState<boolean>(false);
  const [newPlaylistName, setNewPlaylistName] = useState<string>("");

  const apiPath = "/playlists";

  const columns: GridColDef[] = [{
    field: 'name',
    headerName: t('name') as string,
    minWidth: 100,
    flex: 1,
    renderCell: params => {
      const { row } = params;

      return (
        <div className="td-v-center">
          <div className="row-thumb">
            {(row.media && row.media[0]) && <>
              <img src={((row.media[0].disk === "net") ? Settings.apiUrl : AppContext.s["api-url"]) + apiPath + "/" + row.id + "/image/thumb"} alt="Thumb" />
            </>}
            {(!row.media || !row.media[0]) && <img src={AppContext.s["placeholder"]} className="placeholder-thumb" alt="Thumb" />}
          </div>

          {row.name}
        </div>
      );
    }
  },
  {
    field: 'questions-count',
    headerName: "",
    minWidth: 40,
    renderCell: params => {
      const { row } = params;

      return <div className="td-v-center">{`${row.questions_count} ${t('questions')}`}</div>;
    }
  }];

  useEffect(() => {
    const init = async () => {
      const response = await IqSpaceApi.Playlists();
      console.log(response)
      if (response && response.status === 200) {
        const { data } = response.data;

        setPlaylists(exclude ? data.filter(x => x.id !== exclude.id) : data);
      }
    }

    init();
  }, [exclude]);

  const onInsertPlaylist = () => {
    setShowInsertPlaylist(true);
  }

  const onInsertToNewPlaylist = async () => {
    const response = await createItemRequest(Settings.apiUrl + "/playlists", {
      name: newPlaylistName
    });

    if (response && response.status > 200 && onSelect)
      onSelect(response.data.data);
  }

  const onSelected = (o) => {
    setIsLoading(true);

    if (onSelect)
      onSelect(o);

    setIsLoading(false);
  }

  if (!playlists || isLoading)
    return <DataLoader />;

  if (compactMode) {
    return (playlists && playlists.length > 0) ?
      <RemoteSelect name="playlist_id" className="playlists-select"
        label={t("select-playlist")}
        nullOption={false}
        options={playlists}
        value={playlistId}
        onChange={(e, playlistId) => {
          setPlaylistId(playlistId);

          if (onPlaylistChanged)
            onPlaylistChanged(e, playlistId);
        }}
        variant="standard" />
      : <div className="no-playlists">
        <p>{t("no-playlists")}</p>
        <Button className="link-button" onClick={quickCreatePlaylist}>{t("create-playlist")}</Button>
      </div>;
  } else {
    return <>
      <QuickModule
        columns={columns}
        records={playlists}
        onSelected={onSelected}
        disableView />

      {!showInsertPlaylist ?
        <Button onClick={onInsertPlaylist}>{AppContext.r["new-playlist"]}</Button>
        : <div className="quick-new-playlist">
          <TextField variant="outlined" label={t("name")}
            value={newPlaylistName} onChange={(event) => setNewPlaylistName(event.target.value)} />

          <Box marginLeft={1}>
            <Button onClick={onInsertToNewPlaylist}>{t("insert")}</Button>
          </Box>
        </div>}
    </>
  }
}

export default PlaylistsSelect;