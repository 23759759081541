import React from 'react';
import { Form } from 'react-bootstrap';
import IconButton from '@mui/material/IconButton';
import Cookie from 'react-cookies';
import ReactPlayer from 'react-player';
import { connect } from "react-redux";
import MButton from '@mui/material/Button';
import { List, ListItem, ListItemText, ListItemAvatar, Avatar, Collapse, Icon, Tooltip } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LoginIcon from '@mui/icons-material/Login';
import Skeleton from '@mui/material/Skeleton';
import { Link } from "react-router-dom";

import { DataLoader } from '@uderly/react-uderly-ui';
import { useIqSpaceStore } from '../zustand';
import LicencesEnum from './LicencesEnum';
import { setAlert } from '../redux/layoutSlice';
import { withAlert } from 'react-alert';
import AppContext from '../app/AppContext';
import IqSpaceApi from "../api/IqSpace";
import FinalRanking from "./FinalRanking";
import Quiz from "./Quiz";
import { Category } from "./Category";
// import { GameStarted } from "./GameStarted";
import { GameCompleted } from "./GameCompleted";
import BreakingTime from './BreakingTime';
import Timer from './Timer';
import GameStatusEnum from './GameStatusEnum';
import BreakingNews from './BreakingNews';
import SoundsManager from './SoundsManager';
import Ranking from "./Ranking";
import RankingItem from "./RankingItem";
import { withModal } from '../framework/withModal';
import withNotification from 'framework/withNotification';
import Logo from "../uderly/components/Logo";
import BuyTicket, { EventAttendanceStatusesEnum } from "../app/components/BuyTicket";
import withUderlyStore from "../zustand/withUderlyStore";
import Settings from "../app/settings";
import AccessGame from "./AccessGame";
import StartView from "./StartView";

import '../app/assets/scss/quiz/quiz.scss';

const signalR = require("@microsoft/signalr");

export class ViewModeStatesEnum {
    static get Widget() { return 0; }
    static get Mobile() { return 1; }
    static get Icon() { return 2; }
    static get BigScreen() { return 3; }
    static get Recorder() { return 4; }
}

export const viewModeToClassName = (viewMode) => {
    if (viewMode === ViewModeStatesEnum.Widget) return "widget small";
    if (viewMode === ViewModeStatesEnum.Mobile) return "mobile";
    if (viewMode === ViewModeStatesEnum.BigScreen) return "big-screen";
    if (viewMode === ViewModeStatesEnum.Recorder) return "big-screen recorder";

    return "";
}

export const gameStatusToClassName = (gameStatus) => {
    if (gameStatus === null) return "pre-event";
    if (gameStatus?.gameStatus === GameStatusEnum.Started) return "status-started";
    if (gameStatus?.gameStatus === GameStatusEnum.InvitePlayers) return "status-invite-players";

    // TODO: Manage other states

    return "";
}

class Game extends React.Component {
    displayName = Game.name;
    serverTimerElapsedTime = 0;
    localTimerTime = 0;
    connection = React.createRef();

    cache = null; // Data stored in cookie

    get gameCookie() { return AppContext.s['quiz-cookie-name'] + "-" + this.state.eventId; }

    state = {
        status: null,
        event: null,
        eventId: (this.props.id) ? this.props.id : null,
        loaded: false,
        subscribed: false,
        overlayMessage: AppContext.r["loading-event"],
        showNav: false,
        viewMode: this.props.viewMode ? this.props.viewMode : ViewModeStatesEnum.Widget,
        broadcastChatMessages: [],
        player: null,
        chatMessage: "",
        isChatCollapsed: false,
        user: this.props.zustand.user,
        players: null,
        fontSize: 20,
        playerMuted: true,
        mustReconnect: false,
        sounds: {
            enabled: false,
            sound: null,
            loop: false
        },
        fullscreenEnabled: false
    };

    quizRef = React.createRef();

    get IsBigScreen() { return this.state.viewMode === ViewModeStatesEnum.BigScreen || this.state.viewMode === ViewModeStatesEnum.Recorder; }
    get IsWidgetMode() { return this.state.viewMode === ViewModeStatesEnum.Widget; }
    get IsMobile() { return this.state.viewMode === ViewModeStatesEnum.Mobile; }

    onResize() {
        this.width = (this.props.width) ? this.props.width : window.innerWidth;
        this.height = (this.props.height) ? this.props.height : window.innerHeight;

        this.setState({
            fontSize: Math.round(this.height / 25),
            innerWidth: this.width,
            innerHeight: this.height
        });

        let fontSize = 10;

        if (this.IsMobile || this.IsWidgetMode) {
            fontSize = Math.min(this.width / 21, 20);
        }

        if (this.IsBigScreen) {
            fontSize = Math.min(this.width / 21, 24);
            // fontSize = this.height / 21;
        }

        this.setState({
            fontSize: fontSize
        });
    }

    start = async () => {
        if (this.connection.current.state === signalR.HubConnectionState.Disconnected) {
            // console.log("START", this.connection.current.state);

            try {
                await this.connection.current.start();
                console.assert(this.connection.current.state === signalR.HubConnectionState.Connected);
                // console.log("SignalR Connected.", this.connection.current);

                this.onConnected();
            } catch (err) {
                console.assert(this.connection.current.state === signalR.HubConnectionState.Disconnected);
                console.log(err);
                setTimeout(async () => await this.start(), 5000);
            }
        }
    }

    startConnection = async () => {
        if (!this.connection.current) {
            const hubUrl = Settings.apiUrl + "/iqhub";

            const connection = new signalR.HubConnectionBuilder()
                .withUrl(hubUrl)
                .withAutomaticReconnect()
                .build();

            connection.onreconnecting(error => {
                console.assert(connection.state === signalR.HubConnectionState.Reconnecting);
                this.onDisconnected();
            });

            connection.onreconnected(connectionId => {
                console.assert(connection.state === signalR.HubConnectionState.Connected);
                this.onConnected();
            });

            connection.onclose(error => {
                console.assert(connection.state === signalR.HubConnectionState.Connected);
                console.log(error);

                this.onDisconnected();
            });

            connection.on("gameStatus", (gs) => {
                this.onGameStatusChanged(gs);
            });

            connection.on("wavePlayer", (message) => {
                console.log(message);
                this.onWavePlayer(message);
            });

            connection.on("notify", (notification) => {
                this.onNotification(notification);
            });

            connection.on("kickedOut", () => {
                this.onKickedOut();
            });

            connection.on("connectedOnOtherDevice", () => {
                this.onConnectedOnOtherDevice();
            });

            connection.on("chatMessage", (message) => {
                this.onChatMessage(message);
            });

            this.connection.current = connection;
        }

        if (this.connection.current.state === signalR.HubConnectionState.Disconnected)
            await this.start();
    }

    async componentDidMount() {
        this.loadCache();

        // this.onNotification({ 
        //     message: "Hey giocatore, se ti è piaciuto questo gioco e lo vorresti per il tuo evento, contatta iqspace.", 
        //     title: "Attenzione",
        //     duration: 10000
        // });

        const { eventId, event } = this.state;

        if ((!event || event.id !== eventId) && eventId) {
            const response = await IqSpaceApi.Event(eventId);

            if (response) {
                if (response.status === 200) {
                    // Connect to the hub
                    if (!this.connection.current) {
                        this.startConnection();
                    }

                    this.setState({
                        event: response.data.data,
                        loaded: true,
                        overlayMessage: null
                    });
                } else {
                    this.setState({
                        event: null,
                        loaded: true,
                        overlayMessage: AppContext.r['could-not-fetch-event']
                    });
                }
            }
        }

        this.onResize();

        window.addEventListener("resize", this.onResize.bind(this));
    }

    componentWillUnmount() {
        window.stop();

        window.removeEventListener("resize", this.onResize.bind(this));
    }

    togglePlayerMuted = () => {
        this.setState({
            playerMuted: !this.state.playerMuted
        });
    }

    getCurrentElapsedTime() {
        return this.serverTimerElapsedTime - (new Date().getTime() - this.localTimerTime);
    }

    onSubmitAnswer = async (i) => {
        const { player, status, eventId } = this.state;

        if (player && eventId && status.gameStatus === GameStatusEnum.AnswersTimerOn) {
            const request = {
                eventId: parseInt(eventId),
                playerId: parseInt(player.id),
                answer: parseInt(i),
                timeMs: parseInt(this.getCurrentElapsedTime())
            };

            console.log("Submit Answer " + i, request);

            await this.connection.current.invoke("submitAnswer", request);
        }
    }

    onConnecting() {
        this.setState({
            overlayMessage: AppContext.r["rts-connecting"]
        });
    }

    onConnected() {
        this.setState({
            overlayMessage: AppContext.r["rts-connected"]
        });

        this.subscribe();
    }

    loadCache = () => {
        this.cache = Cookie.load(this.gameCookie);
    }

    hub_subscribe = async (eventId, player, userId, asDirector = false) => {
        try {
            let result = null;

            if (asDirector) {
                // Subscribe as a director
                result = await this.connection.current.invoke('subscribeDirector', parseInt(eventId));
            } else if (player && userId) {
                // Subscribe as a player
                const request = {
                    eventId: parseInt(eventId),
                    userId: userId,
                    name: player.Name
                };

                console.log("Subscribe as a player", request, player);
                result = await this.connection.current.invoke('subscribePlayer', request);

                if (!result.player) {
                    // Player subscription didn't go well. Cached player is invalid.
                    this.cache = null;
                    Cookie.remove(this.gameCookie, { path: '/' });
                }
            } else {
                // Subscribe as a viewer
                result = await this.connection.current.invoke('subscribeViewer', parseInt(eventId));
            }

            this.onSubscribed(result);
        } catch (error) {
            this.onSubscriptionFailed(error);
        }
    }

    subscribe = () => {
        const { eventId } = this.state;

        if (eventId) {
            if (this.IsMobile && this.cache && this.state.user
                && this.cache.player && this.cache.player.userId === this.state.user.id) {
                this.hub_subscribe(eventId, this.cache.player, this.state.user.id);
            } else
                this.hub_subscribe(eventId);
        }
    }

    onDisconnected = async () => {
        this.setState({
            overlayMessage: AppContext.r["rts-disconnected"]
        });

        await this.start();
    }

    onConnectionToHubError() {
        this.setState({
            overlayMessage: AppContext.r['rts-couldnt-connect']
        });
    }

    onSubscriptionFailed(error) {
        console.log("onSubscriptionFailed", error);

        this.setState({
            overlayMessage: AppContext.r["rts-subscription-failed"] + JSON.stringify(error)
        });
    }

    onSubscribed = (result) => {
        console.log(result);
        if (result.player) {
            this.cache = {
                player: result.player
            };

            Cookie.save(this.gameCookie, JSON.stringify(this.cache), { path: '/', maxAge: 1 * 24 * 3600 });

            this.onGameAccessed();
        } else {
            if (result.error && result.error.id === 3) {
                const message = (
                    <>
                        <p className="text-center">{AppContext.r['max-players-count-reached']}</p>
                        <i className="far fa-frown-open icon"></i>
                    </>
                );
                console.log(result.error);
                this.props.modal.setView("", message, true);
            }
        }

        this.onGameStatusChanged(result.gameStatus);

        this.setState({
            overlayMessage: "",
            subscribed: true,
            player: result.player,
            broadcastChatMessages: result["chatMessages"]
        });

        setTimeout(this.scrollChatToTheBottom, 500);
    }

    onGameStatusChanged(gameStatus) {
        if (this.props.onGameStatusChanged)
            this.props.onGameStatusChanged(gameStatus);

        if (gameStatus) {
            // Find MyPlayer
            if (gameStatus.players && this.cache)
                for (let p of gameStatus.players) {
                    if (p.id === this.cache.player["id"]) {
                        p['questionId'] = gameStatus.question ? gameStatus.question.questionId : -1;
                        gameStatus.myPlayer = p;
                        break;
                    }
                }

            this.setState({
                status: gameStatus,
                sounds: {
                    sound: gameStatus.isPaused ? "breaking-time" : gameStatus.gameStatus,
                    loop: gameStatus.gameStatus === GameStatusEnum.AnswersTimerOn,
                    enabled: this.state.sounds.enabled
                }
            });

            if (gameStatus.question && gameStatus.question.timerElapsedTime > 0 && gameStatus.gameStatus === GameStatusEnum.AnswersTimerOn) {
                this.serverTimerElapsedTime = gameStatus.question.timerElapsedTime;
                this.localTimerTime = new Date().getTime();
            }
        }
    }

    onChatMessage(messageObject) {
        const { player } = this.state;

        console.log("chat", messageObject, player);
        const maxMessageLength = 50;

        // Display Alert Notification for other people's messages
        if (player && messageObject.userId !== player.userId) {
            const mView = (
                <ListItem alignItems="flex-start" className="line">
                    <ListItemAvatar>
                        <Avatar alt={messageObject.name} src={Settings.apiUrl + "/users/" + messageObject.userId + "/image/thumb"} />
                    </ListItemAvatar>

                    <ListItemText
                        primary={<span className="name">{messageObject.name}</span>}
                        secondary={messageObject.message.substr(0, maxMessageLength) + (messageObject.message.length > maxMessageLength ? "..." : "")} />
                </ListItem>);

            this.props.alert.show(mView, { type: "" });
        }

        const messages = [...this.state.broadcastChatMessages];
        messages.push(messageObject);

        this.setState({
            broadcastChatMessages: messages
        });

        setTimeout(() => this.scrollChatToTheBottom(), 30);
    }

    onGameAccessed = () => {
        this.props.modal.hide();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.zustand.user !== this.state.user) {
            if (!this.state.user)
                this.onLoggedOut();
            else
                this.onAccessed();

            this.setState({
                user: nextProps.zustand.user
            });
        }

        if (nextProps.viewMode !== this.state.viewMode) {
            this.setState({
                viewMode: nextProps.viewMode
            });
        }
    }

    onAccessed = () => {
        this.props.modal.hide();
        this.subscribe();
    }

    onLoggedOut = () => {
        this.quitGame(false);

        this.setState({
            user: null,
            player: null,
        })
    }

    join = async (name, userId) => {
        this.setState({
            overlayMessage: AppContext.r['preloader']
        });

        const { eventId } = this.state;

        if (eventId) {
            this.props.modal.hide();

            const player = {
                Name: name,
            };

            await this.hub_subscribe(eventId, player, userId, false);

            // Add game bookmark

            const { event } = this.state;

            const gameBookmark = {
                title: event.title,
                event_id: event.id,
                accessed_at: new Date()
            };

            useIqSpaceStore.getState().addGameBookmark(gameBookmark);

            await IqSpaceApi.AttendEvent(eventId, null, true);
        }

        this.setState({
            overlayMessage: ''
        });
    }

    onPreviewSubmitAnswer = (i) => {
        if (!this.state.player && this.IsMobile) {
            //TODO: ASK to play
            // this.onPlay();
        }
    }

    onPlay = async () => {
        const { user } = this.state;

        if (!user && this.props.onAccess) {
            this.props.onAccess(() => { this.onAccessed(); this.onPlay(); });
            return;
        }

        // Buy ticket if required

        const { event } = this.state;

        if (event.store.licence_id <= LicencesEnum.Uderly && !event.is_free && event.price > 0) {
            const response = await IqSpaceApi.AttendEvent(event.id, EventAttendanceStatusesEnum.paymentIntent);
            // console.log(response, event);
            if (response && response.status === 200 && response.data) {
                const eventAttendance = response.data.data;
                // console.log(eventAttendance)

                if (eventAttendance.status_id !== EventAttendanceStatusesEnum.paid) {
                    this.props.modal.setView(AppContext.r["buy-ticket"],
                        <BuyTicket modal={this.props.modal} event={event} store={event.store} authActions={this.props.authActions} />, true);
                    return;
                } else {
                    this.join(user.gamename, user.id)
                }
            }
        } else {
            this.join(user.gamename, user.id)
        }
    }

    onNotification = (notification) => {
        const { message, title, severity, duration } = notification;
        console.log(notification)

        this.props.setAlert({ open: true, message: message, title: title, autoHideDuration: duration, severity: severity });
    }

    onWavePlayer = (message) => {
        this.props.modal.setView("", AppContext.r["hello"] + " " + this.state.user.name, true);
    }

    onKickedOut = () => {
        this.props.modal.setView("", AppContext.r['kicked-out'], true);

        this.quitGame(false);
    }

    onConnectedOnOtherDevice = () => {
        const isPlaying = this.state.player !== null;

        this.quitGame(false);

        if (isPlaying && this.IsMobile)
            this.props.modal.setView("", AppContext.r['logged-on-other-device'], true);
    }

    quitGame = (informHub = false) => {
        const { player, eventId } = this.state;

        // const c = Cookie.load(this.gameCookie);
        // console.log(this.gameCookie, c);
        if (informHub && eventId && player) {
            const request = {
                eventId: parseInt(eventId),
                playerId: parseInt(player.id),
            }
            console.log("quitGame", request, eventId);

            this.connection.current.invoke("quitGame", request);
        }

        this.cache = null;
        Cookie.remove(this.gameCookie, { path: '/' });

        this.props.modal.hide();
        this.setState({
            player: null
        })
    }

    onQuitGame = () => {
        this.props.modal.confirm(AppContext.r["quit"], AppContext.r['confirm-quit-game'], () => this.quitGame(true));
    }

    onSendChatMessage = async (e) => {
        e.preventDefault();

        const { chatMessage, player, eventId } = this.state;

        if (eventId && player && this.connection.current) {
            const request = {
                eventId: parseInt(eventId),
                playerId: parseInt(player.id),
                message: chatMessage,
                userId: parseInt(player.userId),
                toPlayerId: -1, // Group broadcast
                timestamp: 0
            };

            await this.connection.current.invoke("chat", request);
        }

        this.setState({
            chatMessage: ""
        })
    }

    onShowPlayers = async () => {
        const { user, eventId } = this.state;

        const userId = user ? user.id : -1;

        const request = {
            eventId: parseInt(eventId),
            userId: parseInt(userId)
        };

        console.log("onShowPlayers", request, eventId);

        const result = await this.connection.current.invoke("getPlayers", request);

        if (result) {
            let i = 0;
            const players = result.players.map((player) =>
                <RankingItem position={++i} key={player.id} player={player} showMedal={false} showScore={false} isPartial={false}
                    you={result.myPlayer && result.myPlayer.id === player.id} />
            );

            this.props.modal.setView(AppContext.r["players"], <div className="players-view">{players}</div>, true);
            this.props.modal.setFree();
        }
    }

    isFullScreen = () => !!(document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.msFullscreenElement);

    toggleFullscreen = () => {
        const fullscreenEnabled = this.isFullScreen();

        if (fullscreenEnabled)
            document.exitFullscreen();
        else
            document.documentElement.requestFullscreen();

        this.setState({ fullscreenEnabled: !fullscreenEnabled });
    }

    playerOptions() {
        let quitGameButton = "";
        let showPlayersButton = "";

        const { player, status, user, sounds, fullscreenEnabled } = this.state;

        return (
            <div>
                {quitGameButton}
                {showPlayersButton}

                <div className="player-view">
                    {!this.IsBigScreen && this.chatBox()}

                    <div className="options-bar">
                        {player &&
                            <>
                                <Tooltip placement="top" title={<tooltip>{AppContext.r["game-option-quit-tooltip"]}</tooltip>}>
                                    <IconButton aria-label="logout" onClick={this.onQuitGame}>
                                        <Icon className="fas fa-sign-out-alt" />
                                    </IconButton>
                                </Tooltip>

                                <Tooltip placement="top" title={<tooltip>{AppContext.r["game-option-players-tooltip"]}</tooltip>}>
                                    <IconButton aria-label="logout" onClick={this.onShowPlayers}>
                                        <Icon className="fas fa-users" />
                                    </IconButton>
                                </Tooltip>
                            </>}

                        {!AppContext.s['disable-sounds'] &&
                            !sounds.enabled ?
                            <Tooltip placement="top" title={<tooltip>{AppContext.r["game-option-sounds-on-tooltip"]}</tooltip>}>
                                <IconButton aria-label="volume-mute" onClick={() => this.setState({
                                    sounds: { ...this.state.sounds, enabled: true, sound: GameStatusEnum.CorrectAnswer }
                                })}>
                                    <Icon className="fas fa-volume-mute" />
                                </IconButton>
                            </Tooltip> :
                            <Tooltip placement="top" title={<tooltip>{AppContext.r["game-option-sounds-off-tooltip"]}</tooltip>}>
                                <IconButton aria-label="volume-up" onClick={() => this.setState({
                                    sounds: { ...this.state.sounds, enabled: false, sound: null }
                                })}>
                                    <Icon className="fas fa-volume-up" />
                                </IconButton>
                            </Tooltip>}

                        {!AppContext.s['disable-fullscreen'] &&
                            !fullscreenEnabled ?
                            <Tooltip placement="top" className="fullscreen-icon on" title={<tooltip>{AppContext.r["game-option-fullscreen-on-tooltip"]}</tooltip>}>
                                <IconButton aria-label="fullscreen-on" onClick={() => { this.toggleFullscreen(); }}>
                                    <Icon className="fas fa-expand" />
                                </IconButton>
                            </Tooltip> :
                            <Tooltip placement="top" className="fullscreen-icon off" title={<tooltip>{AppContext.r["game-option-fullscreen-off-tooltip"]}</tooltip>}>
                                <IconButton aria-label="fullscreen-off" onClick={() => { this.toggleFullscreen(); }}>
                                    <Icon className="fas fa-compress" />
                                </IconButton>
                            </Tooltip>}
                    </div>
                </div>

                {(!player
                    && this.IsMobile
                    && status
                    && status.gameStatus !== GameStatusEnum.Completed
                    && status.gameStatus !== GameStatusEnum.NotStarted) &&
                    <div className="access-game-overlay">
                        <div className="access-game-content">
                            {!user && this.props.authActions && (
                                <>
                                    <LoginIcon />

                                    <p>
                                        <MButton onClick={this.props.authActions.onLogin}>{AppContext.r["login"]}</MButton>

                                        {AppContext.r["login-to-play"]}
                                    </p>
                                </>
                            )}

                            {(user && status) && <AccessGame onClickPlay={this.onPlay} />}
                        </div>
                    </div>}
            </div>
        );
    }

    scrollChatToTheBottom = () => {
        const el = document.getElementById("chat");

        if (el)
            el.scrollTop = el.scrollHeight * 2;
    }

    chatBox = () => {
        const { player, broadcastChatMessages, status } = this.state;

        if (!status || status.GameStatus === GameStatusEnum.NotStarted || this.IsWidgetMode)
            return "";

        return (
            <div className="chat-box">
                {!broadcastChatMessages && <div className="skeleton-preloader">
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                </div>}

                {broadcastChatMessages && (
                    <>
                        {!this.IsBigScreen &&
                            <div className="toggle-chat" onClick={() => {
                                this.setState({ isChatCollapsed: !this.state.isChatCollapsed });
                                // Scroll chat to the bottom
                                setTimeout(this.scrollChatToTheBottom, 100);
                            }}>
                                {this.state.isChatCollapsed ? <ExpandLess /> : <ExpandMore />}
                            </div>}

                        <Collapse in={!this.state.isChatCollapsed} timeout="auto" unmountOnExit>
                            <>
                                {(!this.IsBigScreen && broadcastChatMessages && broadcastChatMessages.length === 0) && (
                                    <p className="no-chat-messages">{AppContext.r["no-chat-messages"]}</p>)}

                                <List id="chat">
                                    {broadcastChatMessages.map(c => {
                                        return (
                                            <ListItem alignItems="flex-start" className="line">
                                                <ListItemAvatar>
                                                    <Avatar alt={c.name}
                                                        sx={c.colorHex ? { bgcolor: c.colorHex } : {}}
                                                        src={Settings.apiUrl + "/users/" + c.userId + "/image/thumb"} />
                                                </ListItemAvatar>

                                                <ListItemText
                                                    primary={<span className={(player && player.userId === c.userId) ? "name you" : "name"}>{c.name}</span>}
                                                    secondary={c.message} />
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            </>
                        </Collapse>
                    </>)}

                {(!this.IsBigScreen && !player) &&
                    <p className="access-to-chat">{AppContext.r["access-to-chat"]}</p>}

                {!this.IsBigScreen &&
                    <Form onSubmit={this.onSendChatMessage}>
                        <div className="options">
                            {/* <div className="f1"> */}
                            <input type="text" maxLength="200" value={this.state.chatMessage}
                                disabled={!player}
                                onChange={(e) => this.setState({ chatMessage: e.target.value })}></input>
                            {/* </div> */}

                            <IconButton type="submit" className="send-text-button" aria-label="send"
                                disabled={this.state.chatMessage.length === 0}>
                                <SendIcon />
                            </IconButton>
                        </div>
                    </Form>}
            </div>
        );
    }

    render() {
        const { event, viewMode, loaded, subscribed, user, player, sounds } = this.state;

        let view = "";

        if (viewMode === ViewModeStatesEnum.Icon)
            view = (
                <div>
                    <img className="view-mode-icon" src={AppContext.s['bulb']} alt="bulb" />
                </div>);
        const gameStatus = this.state.status;

        if (viewMode !== ViewModeStatesEnum.Icon) {
            if (!event && loaded) {
                console.log(event, loaded)
                view = (
                    <div className="centered-comic-message">
                        <h1>404</h1>
                        <p>{AppContext.r['event-not-existing']}</p>
                    </div>);
            }

            if (this.state.status === null && event && subscribed) {
                console.log("PreEvent");
                view = <StartView event={event} viewMode={viewMode} gameStatus={null}
                    authActions={this.props.authActions} modal={this.props.modal} />;
            }

            const breakingNewsMessage = /*this.IsBigScreen ? 
                <div>
                    {AppContext.r["invite-players"]} 
                    <u className="primary-color">{AppContext.s.accessUrl ? AppContext.s.accessUrl : AppContext.Shop ? (AppContext.Shop.subdomain + "." + AppContext.s["domain"]) : ""}</u>
                </div> : */"";

            if (gameStatus) {
                const gameStatusIndex = gameStatus.gameStatus;

                if ((gameStatus.isPaused || gameStatus.isShowingAdvertisement) && !gameStatus.completed) {
                    view = (
                        <div>
                            <BreakingTime gameStatus={gameStatus} isRecorder={this.state.viewMode === ViewModeStatesEnum.Recorder} />
                            <BreakingNews message={breakingNewsMessage} />
                        </div>);
                } else {
                    if (gameStatusIndex === GameStatusEnum.Started && !gameStatus.question
                        || (gameStatus.question && !gameStatus.question.Question && gameStatus.question.Image == null)) {
                        console.log("game Started");

                        view = (
                            <>
                                {/* <GameStarted viewMode={this.state.viewMode} event={this.state.event} showMessage={true} /> */}
                                <StartView event={event} viewMode={viewMode} gameStatus={gameStatus}
                                    authActions={this.props.authActions} modal={this.props.modal} />

                                {/* <BreakingNews message={breakingNewsMessage} /> */}
                            </>);
                    }

                    if (gameStatusIndex >= GameStatusEnum.Question
                        && gameStatusIndex <= GameStatusEnum.FinalRanking && gameStatusIndex !== GameStatusEnum.ImageAgain) {
                        const breakingNews = (gameStatusIndex === GameStatusEnum.AnswersTimerOff || gameStatusIndex === GameStatusEnum.CorrectAnswer)
                            ? (<BreakingNews message={breakingNewsMessage} />) : "";

                        view = (
                            <div>
                                <Quiz gameStatus={gameStatus}
                                    allowResponding={user && player && this.state.viewMode === ViewModeStatesEnum.Mobile}
                                    onPreviewSubmitAnswer={this.onPreviewSubmitAnswer} onSubmitAnswer={this.onSubmitAnswer} />

                                {(gameStatus.question && (gameStatusIndex === GameStatusEnum.AnswersTimerOn ||
                                    gameStatusIndex === GameStatusEnum.CorrectAnswer)) &&
                                    <Timer durationMs={gameStatus.question.timerElapsedTime} marginTop={this.props.marginTop} />}
                                {breakingNews}
                            </div>);
                    }

                    if (gameStatusIndex === GameStatusEnum.InvitePlayers) {
                        console.log("invite players");
                        view = (<div>
                            {/* <GameStarted viewMode={this.state.viewMode} event={this.state.event} showMessage={true} /> */}
                            <StartView event={event} viewMode={viewMode} gameStatus={gameStatus}
                                authActions={this.props.authActions} modal={this.props.modal} />
                            <BreakingNews message={breakingNewsMessage} />
                        </div>);
                    }

                    if (gameStatusIndex === GameStatusEnum.Category)
                        view = <Category gameStatus={gameStatus} />;

                    if (gameStatusIndex === GameStatusEnum.Image && gameStatus.question && gameStatus.question.showImage) {
                        // Horrible hack to tell apart the new media system with the old one
                        // if (gameStatus.question.questionId > 1700)
                        view = (<img className="question-image" src={Settings.apiUrl + "/questions/" + gameStatus.question.questionId + "/image"} alt="" />);
                        // else
                        // view = (<img className="question-image" src={gameStatus.question.image} alt="" />);
                    }

                    if (gameStatusIndex === GameStatusEnum.Video && gameStatus.question)
                        view = (
                            <div className="video-player">
                                <ReactPlayer url={gameStatus.question.videoUrl}
                                    width={window.innerWidth} height={this.IsBigScreen ? (window.innerHeight) - 150 : 360}
                                    muted={this.state.playerMuted} playing={true} loop={true} />

                                <MButton variant="outlined" onClick={this.togglePlayerMuted}>
                                    {this.state.playerMuted ? AppContext.r["enable-audio"] : AppContext.r["disable-audio"]}
                                </MButton>
                            </div>);

                    // [...gameStatus.players, ...gameStatus.players, ...gameStatus.players, ...gameStatus.players, ...gameStatus.players, ...gameStatus.players, ...gameStatus.players, ...gameStatus.players, ...gameStatus.players, ...gameStatus.players, ...gameStatus.players]
                    if (gameStatusIndex >= GameStatusEnum.Teams && gameStatusIndex <= GameStatusEnum.Ranking) {
                        view = (
                            <div>
                                <Quiz gameStatus={gameStatus} ref={this.quizRef} />
                                <Ranking gameStatus={gameStatus} players={gameStatus.players} colsPerPage={this.IsWidgetMode || (this.IsMobile && this.state.innerWidth < 800) ? 1 : 3} />
                                <BreakingNews message={breakingNewsMessage} />
                            </div>);
                    }

                    if (gameStatusIndex === GameStatusEnum.FinalRanking) {
                        view = (
                            <div>
                                <Quiz gameStatus={gameStatus} ref={this.quizRef} />
                                <FinalRanking gameStatus={gameStatus} players={gameStatus.players} />
                                <BreakingNews message={breakingNewsMessage} />
                            </div>
                        )
                    }

                    if (gameStatusIndex === GameStatusEnum.Completed || gameStatus.completed || gameStatus.expired)
                        view = (<GameCompleted event={event} gameStatus={gameStatus} viewMode={this.state.viewMode}
                            colsPerPage={this.IsWidgetMode || (this.IsMobile && this.state.innerWidth < 800) ? 1 : 3} />);
                }
            }
        }

        const gameViewStyle = {
            fontSize: this.state.fontSize
        }

        const displayCustomLogo = event && event.store && event.store.media && event.store.media[0] && event.store.licence_id === 2;

        return (
            <div className={`${viewModeToClassName(viewMode)} ${gameStatusToClassName(gameStatus)}`} style={gameViewStyle}>
                {(viewMode !== ViewModeStatesEnum.Widget && viewMode !== ViewModeStatesEnum.Icon) &&
                    <Link to={"/" + ((displayCustomLogo ? event.store.subdomain : ""))} className="game-logo" style={{ marginTop: this.props.marginTop ?? 0 }}>
                        {!displayCustomLogo ? <Logo mini dark marginTop={this.props.marginTop} /> :
                            <img src={Settings.apiUrl + "/stores/" + event.store.id + "/image/thumb"} alt={event.store.name} />}
                    </Link>}

                <div className="game-view main-background-decoration">
                    {view}
                </div>

                {this.s}

                <SoundsManager sound={sounds.sound} loop={sounds.loop} enabled={sounds.enabled} />

                {this.playerOptions()}

                {event && this.state.overlayMessage && (
                    <div className="connection-info-overlay">
                        <DataLoader light />
                        <p>{this.state.overlayMessage}</p>
                    </div>
                )}
            </div>
        );
    }
}

const mapDispatchToProps = () => ({
    setAlert
});

export default connect(null, mapDispatchToProps())(withModal((withAlert()(withNotification(withUderlyStore(Game))))))