import React, { ReactNode } from "react";

export const hasPermission = (user, permission) => user && user.roles[0] &&
    user.roles[0].permissions.filter(p => p.title === permission).length > 0;

export const textSplitter = (text: string, length?: number) => {
    if (!length)
        length = text.length / 2;

    const jsx: ReactNode[] = [];
    const words = text.split(" ");

    const lineLength = Math.min(10, length);

    let line = "";

    for (let index in words) {
        const word = words[index];

        line += (word + " ");

        if (line.length >= lineLength || parseInt(index) === (words.length - 1)) {
            jsx.push(<h1>{line.trim()}</h1>);
            line = "";
        }
    }

    return jsx;
}